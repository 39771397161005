<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Fee Schedule Name"
            v-model="feeScheduleName"
            required
            :rules="rules.name"
            :color="colorTheme.textField"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-layout>
        <v-flex auto>
          <v-select
            label="Fee Type"
            v-model="feeTypeSelect"
            :items="billingFeeTypes"
            item-text="displayName"
            item-value="value"
            required
            :rules="rules.feeType"
            :color="colorTheme.dropDown"
            :item-color="colorTheme.dropDownItem"
          ></v-select>
        </v-flex>
        <v-flex auto>
          <v-select
            label="Discount Type"
            v-model="discountTypeSelect"
            :items="billingDiscountTypes"
            item-text="displayName"
            item-value="value"
            required
            :rules="rules.discountType"
            :color="colorTheme.dropDown"
            :item-color="colorTheme.dropDownItem"
          ></v-select>
        </v-flex>
        <v-flex auto>
          <v-select
            label="AUM Basis"
            v-model="aumBasisTypeSelect"
            :items="filteredBillingAumBasisTypes"
            item-text="displayName"
            item-value="value"
            required
            :rules="rules.aumBasisType"
            :color="colorTheme.dropDown"
            :item-color="colorTheme.dropDownItem"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Monthly Minimum Fee"
            v-model="feeMonthlyMinimum"
            prefix="$"
            :rules="rules.monthlyMinimumFee"
            :color="colorTheme.textField"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapState } from "vuex";
import { filterAumBasisTypes } from "@/utils/aum-types";

export default {
  props: [
    "formValidation",
    "name",
    "feeType",
    "discountType",
    "aumBasisType",
    "monthlyMinimumFee",
    "scheduleFormShow"
  ],
  emits: [
    "update:formValidation",
    "update:name",
    "update:feeType",
    "update:discountType",
    "update:aumBasisType",
    "update:monthlyMinimumFee",
  ],
  data: () => ({
    rules: {
      name: [(v) => !!v || "Name is required"],
      discountType: [(v) => !!v || "Discount Type is required"],
      aumBasisType: [(v) => !!v || "AUM Basis Type is required"],
      feeType: [(v) => !!v || "Fee Type is required"],
      monthlyMinimumFee: [
        (v) => /\d+/.test(v) || "A number required",
        (v) => v >= 0 || "Positive number required"
      ]
    },
  }),
  computed: {
    ...mapState("feeSchedule", [
      "billingDiscountTypes",
      "billingAumBasisTypes",
      "billingFeeTypes"
    ]),
    ...mapState("hierarchyMode", ["hierarchyTag"]),
    ...mapState("userConfig", ["colorTheme"]),
    feeScheduleName: {
      get() {
        return this.name;
      },
      set(newValue) {
        this.$emit("update:name", newValue);
      }
    },
    feeTypeSelect: {
      get() {
        return this.feeType;
      },
      set(newValue) {
        this.$emit("update:feeType", newValue);
      }
    },
    discountTypeSelect: {
      get() {
        return this.discountType;
      },
      set(newValue) {
        this.$emit("update:discountType", newValue);
      }
    },
    aumBasisTypeSelect: {
      get() {
        return this.aumBasisType;
      },
      set(newValue) {
        this.$emit("update:aumBasisType", newValue);
      }
    },
    feeMonthlyMinimum: {
      get() {
        return this.monthlyMinimumFee;
      },
      set(newValue) {
        this.$emit("update:monthlyMinimumFee", newValue);
      }
    },
    valid: {
      get() {
        return this.formValidation;
      },
      set(newValue) {
        this.$emit("update:formValidation", newValue);
      }
    },
    filteredBillingAumBasisTypes() {
      return filterAumBasisTypes(this.hierarchyTag.val, this.billingAumBasisTypes);
    }
  },
  watch: {
    scheduleFormShow(newVal) {
      if (newVal) {
        this.defaultMonthlyMinimum();
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    defaultMonthlyMinimum() {
      this.feeMonthlyMinimum = 0;
    }
  }
}
</script>
