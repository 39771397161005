function filterAumBasisTypes(currentHierarchy, aumBasisTypes) {
  if (!aumBasisTypes) {
    return [];
  }
  switch (currentHierarchy) {
    case "ACCOUNT_FIRM":
      return aumBasisTypes.firmAccountSleeveHierarchy;
    case "MODEL_MANAGER":
      return aumBasisTypes.modelManagerHierarchy;
    case "ACCOUNT_GROUP":
      return aumBasisTypes.groupAccountHierarchy;
    default:
      return [];
  }
}
module.exports = {
  filterAumBasisTypes
};
