<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="600px"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title>Delete Tier</v-card-title>
      <v-card-text class="pt-3 mt-2 mb-0">{{ deleteFirstTier }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :style="colorTheme.textColor"
          :color="colorTheme.buttonWhite"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn dark :color="colorTheme.button" @click="confirm">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["showDialog", "tierToDelete"],
  emits: ["update:showDialog", "update:tierToDelete"],
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show() {
      return this.showDialog;
    },
    deleteFirstTier() {
      if (this.tierToDelete) {
        let content = "";
        if (this.tierToDelete.activationLevel === 0) {
          content += "Deleting first tier might lead to unexpected behaviour. ";
        }
        content += "Are you sure you want to delete this tier?";
        return content;
      }
      return "";
    }
  },
  methods: {
    ...mapActions("feeTiers", ["deleteFeeTier"]),
    async confirm() {
      try {
        await this.deleteFeeTier({ id: this.tierToDelete.id });
        this.cancel();
      } catch (err) {
        console.log(err);
      }
    },
    cancel() {
      this.$emit("update:tierToDelete", null);
      this.$emit("update:showDialog", false);
    }
  }
};
</script>
