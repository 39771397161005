var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('delete-tier-dialog',{attrs:{"showDialog":_vm.showDeleteTierDialog,"tierToDelete":_vm.tierToDelete},on:{"update:showDialog":function($event){_vm.showDeleteTierDialog=$event},"update:show-dialog":function($event){_vm.showDeleteTierDialog=$event},"update:tierToDelete":function($event){_vm.tierToDelete=$event},"update:tier-to-delete":function($event){_vm.tierToDelete=$event}}}),_c('add-update-tier-dialog',{attrs:{"showDialog":_vm.showAddUpdateTierDialog,"tierToModify":_vm.tierToModify},on:{"update:showDialog":function($event){_vm.showAddUpdateTierDialog=$event},"update:show-dialog":function($event){_vm.showAddUpdateTierDialog=$event},"update:tierToModify":function($event){_vm.tierToModify=$event},"update:tier-to-modify":function($event){_vm.tierToModify=$event}}}),(_vm.showTable)?_c('v-row',[(!_vm.inherited)?_c('v-col',[(_vm.hasTiers)?_c('v-data-table',{staticClass:"scrollable-table__large",attrs:{"fixed-header":"","items":_vm.feeTiers,"headers":_vm.tierHeaders,"item-key":"id","disable-sort":"","hide-default-footer":_vm.feeTiers.length <= 10},scopedSlots:_vm._u([{key:"item.aum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.readableNumber(item.activationLevel))+" ")]}},{key:"item.rateOrAmount",fn:function(ref){
var item = ref.item;
return [(item.billingTierType !== 'Percent')?_c('span',[_c('span',{staticClass:"teal--text text--darken-3"},[_vm._v("$")]),_vm._v(" "+_vm._s(item.rateOrAmount)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getPercentageFromNumber(item.rateOrAmount))+" "),_c('span',{staticClass:"teal--text text--darken-3"},[_vm._v("%")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"edit-icon",attrs:{"small":""},on:{"click":function($event){return _vm.modifyFeeTierDialog(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"delete-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteFeeTierDialog(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}):_vm._e()],1):_c('v-col',[(_vm.hasTiers)?_c('v-data-table',{class:{'scrollable-table__large' : true, 'show-as-disabled' : true},attrs:{"fixed-header":"","items":_vm.feeTiers,"headers":_vm.tierHeaders,"item-key":"id","disable-sort":"","hide-default-footer":_vm.feeTiers.length <= 10},scopedSlots:_vm._u([{key:"item.aum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.readableNumber(item.activationLevel))+" ")]}},{key:"item.rateOrAmount",fn:function(ref){
var item = ref.item;
return [(item.billingTierType !== 'Percent')?_c('span',[_c('span',{staticClass:"teal--text text--darken-3"},[_vm._v("$")]),_vm._v(" "+_vm._s(item.rateOrAmount)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getPercentageFromNumber(item.rateOrAmount))+" "),_c('span',{staticClass:"teal--text text--darken-3"},[_vm._v("%")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"edit-icon",attrs:{"small":"","disabled":""},on:{"click":function($event){return _vm.modifyFeeTierDialog(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"delete-icon",attrs:{"small":"","disabled":""},on:{"click":function($event){return _vm.deleteFeeTierDialog(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}):_vm._e()],1)],1):_vm._e(),(!_vm.inherited)?_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.isFeeScheduleSelected,"color":_vm.colorTheme.button,"dark":""},on:{"click":_vm.openNewTierDialog}},[_vm._v(" Add New Tier ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }