<template>
  <div>
    <warning-dialog
      message="Something is wrong! Reload to avoid problems."
      :showDialog.sync="showLinkWarning"
    ></warning-dialog>
    <error-dialog
      message="Loading exclusions failed! Try again"
      :showDialog.sync="showErrorDialog"
    ></error-dialog>
    <new-exclusion-dialog
      :showDialog.sync="showNewExclusionDialog"
      @refresh="refresh"
    ></new-exclusion-dialog>
    <update-exclusion-dialog
      :showDialog.sync="showUpdateExclusionDialog"
      :exclusionToUpdate.sync="exclusionToUpdate"
      @refresh="refresh"
    ></update-exclusion-dialog>
    <delete-exclusion-dialog
      :showDialog.sync="showDeleteExclusionDialog"
      :exclusion.sync="exclusionToDelete"
      @refresh="refresh"
    ></delete-exclusion-dialog>
    <h2 class="mb-2">Exclusions</h2>
    <v-row>
      <v-col>
        <v-btn
          class="mb-2"
          :color="colorTheme.button"
          dark
          @click="createNewExclusion"
          :disabled="!isFeeTypeSelected"
        >
          Create New Exclusion
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-text-field
          clearable
          v-model="exclusionSearch"
          append-icon="mdi-magnify"
          label="Search exclusions"
          single-line
          hide-details
          :color="colorTheme.textField"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        fixed-header
        class="scrollable-table__large"
        :headers="exclusionTableHeaders"
        :items="exclusions"
        :loading="loading"
        :options.sync="options"
        :server-items-length="numberOfExclusions"
        item-key="id"
        single-select
        :footer-props="{
          'items-per-page-options': rowsPerPage,
          disablePagination: loading,
          disableItemsPerPage: loading,
        }"
      >
        <template v-slot:progress>
          <v-progress-linear
            :color="colorTheme.progress"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.billingFeeType`]="{ item }">
          <td>
            {{ mapFeeType(item.billingFeeType) }}
          </td>
        </template>
        <template v-slot:[`item.exclusionPercentage`]="{ item }">
          <td>
            {{ item.exclusionPercentage * 100 }}
          </td>
        </template>
        <template v-slot:[`item.expirationDate`]="{ item }">
          <td>
            {{ setDate(item.expirationDate) }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="item.entity1 !== null && item.entity1.id == billingEntity.id"
            small
            @click="updateExclusionDialog(item)"
            class="edit-icon"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="item.entity1 !== null && item.entity1.id == billingEntity.id"
            small
            @click="deleteExclusionDialog(item)"
            class="delete-icon"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <cell-clipboard :text="item.id" />
        </template>
        <template v-slot:[`item.billingSecurityEntityId`]="{ item }">
          <cell-clipboard :text="item.billingSecurityEntityId" />
        </template>
        <template v-slot:[`item.entity1`]="{ item }">
          <td>
            <v-card-text
              v-if="
                item.entity1 !== null && item.entity1.id !== billingEntity.id
              "
              class="link-text"
              @click="linkToInherited(item.entity1, item.entity1Hierarchies)"
            >
              {{ item.entity1.name }}
            </v-card-text>
          </td>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" :color="colorTheme.alert">
            {{ noDataText }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapFeeTypeValueToDisplayName } from "@/utils/mapping-util.js";
import { equalObjects } from "@/utils/object-operations.js";
import { mapActions, mapMutations, mapState } from "vuex";
import DeleteExclusionDialog from "@/components/exclusions/dialogs/DeleteExclusion.vue";
import UpdateExclusionDialog from "@/components/exclusions/dialogs/UpdateExclusion.vue";
import NewExclusionDialog from "../exclusions/dialogs/NewExclusion.vue";
import CellClipboard from "@/components/common/CellClipboard.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import amplitude from "amplitude-js";

export default {
  components: {
    DeleteExclusionDialog,
    UpdateExclusionDialog,
    NewExclusionDialog,
    CellClipboard,
    ErrorDialog,
    WarningDialog,
  },
  data: () => ({
    showLinkWarning: false,
    pageReseting: false,
    exclusionSearch: "",
    exclusions: [],
    numberOfExclusions: 0,
    showErrorDialog: false,
    loading: false,
    showNewExclusionDialog: false,
    showUpdateExclusionDialog: false,
    showDeleteExclusionDialog: false,
    exclusionToUpdate: null,
    exclusionToDelete: null,
    exclusionTableHeaders: [
      {
        text: "ID",
        value: "id",
        align: "start",
        sortable: false,
      },
      {
        text: "Fee Type",
        value: "billingFeeType",
        align: "start",
      },
      {
        text: "Security ID",
        value: "billingSecurityEntityId",
        align: "start",
      },
      {
        text: "Security Name",
        value: "securityName",
        align: "start",
      },
      {
        text: "Ticker",
        value: "ticker",
        align: "start",
      },
      {
        text: "CUSIP",
        value: "cusip",
        align: "start",
      },
      {
        text: "Inherited from",
        value: "entity1",
        align: "start",
      },
      {
        text: "Percentage",
        value: "exclusionPercentage",
        align: "start",
      },
      {
        text: "Amount",
        value: "exclusionAmount",
        align: "start",
      },
      {
        text: "Expiration Date",
        value: "expirationDate",
        align: "start",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "start",
      },
    ],
  }),
  computed: {
    ...mapState("exclusions", ["pagination", "resetExclusions"]),
    ...mapState("feeSchedule", ["billingEntity", "billingFeeTypes"]),
    ...mapState("userConfig", ["colorTheme", "rowsPerPage"]),
    ...mapState("hierarchyMode", ["hierarchyTag", "hierarchyTags"]),
    search() {
      if (this.exclusionSearch === null) {
        return "";
      } else {
        return this.exclusionSearch;
      }
    },
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    sortBy() {
      if (this.options.sortBy && this.options.sortBy.length === 1) {
        return this.options.sortBy[0];
      } else {
        return null;
      }
    },
    sortDirection() {
      if (this.options.sortDesc && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] === false ? "Ascending" : "Descending";
      } else {
        return "Ascending";
      }
    },
    isFeeTypeSelected() {
      const isSelected = this.billingEntity.name === null ? false : true;
      return isSelected;
    },
    noDataText() {
      return "No exclusions defined yet";
    },
    currentHierarchy() {
      return this.hierarchyTag.val;
    },
  },
  watch: {
    options: {
      deep: true,
      handler(val, oldVal) {
        if (!this.$cookies.isKey("token")) {
          return;
        }
        if (
          !equalObjects(val.sortBy, oldVal.sortBy) ||
          !equalObjects(val.sortDesc, oldVal.sortDesc)
        ) {
          this.refresh();
        } else if (val.page !== oldVal.page && !this.pageReseting) {
          this.refresh();
        } else if (val.itemsPerPage !== oldVal.itemsPerPage) {
          this.refreshOrCut(val.itemsPerPage);
        }
      }
    },
    exclusionSearch() {
      this.filter();
    },
    resetExclusions(val) {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      if (val) {
        this.setResetExclusions(false);
        this.refresh({ reset: true });
      }
    }
  },
  methods: {
    ...mapMutations("exclusions", [
      "setPagination",
      "resetPagination",
      "resetPage",
      "setResetExclusions"
    ]),
    ...mapActions("exclusions", ["loadExclusions"]),
    ...mapActions("feeSchedule", ["goToInherited"]),
    ...mapActions("hierarchyMode", ["setHierarchyTag"]),
    async filter() {
      this.pageReseting = true;
      this.resetPage();
      await this.refresh();
      this.pageReseting = false;
    },
    async refreshOrCut(pageSize) {
      let array = this.exclusions;
      if (pageSize > array.length) {
        amplitude.getInstance().logEvent("Fee Assignment - Exclusions [Rows Per Page]", {
          "Number of rows selected": this.options.itemsPerPage
        });
        await this.refresh();
      } else {
        this.exclusions = this.exclusions.slice(0, pageSize);
      }
    },
    async refresh(reset) {
      this.loading = true;
      let oldOptions = this.options;
      if (reset) {
        this.resetPagination();
      }
      let newOptions = this.options;
      // if pagination and sort are the same, call loadExclusions
      // if pagination or sort is changed, loadExclusions will be called from next watch
      if (
        oldOptions.page == newOptions.page &&
        oldOptions.itemsPerPage == newOptions.itemsPerPage &&
        equalObjects(oldOptions.sortBy, newOptions.sortBy) &&
        equalObjects(oldOptions.sortDesc, newOptions.sortDesc)
      ) {
        let result = await this.loadExclusions({
          searchKeyword: this.search,
          sortKey: this.sortBy,
          sortDirection: this.sortDirection,
          page: this.page,
          pageSize: this.pageSize
        });
        if (result && result !== "ERROR") {
          this.exclusions = result.exclusions;
          this.numberOfExclusions = result.numberOfExclusions;
        } else if (result != null) {
          this.showErrorDialog = true;
        }
      }
      this.loading = false;
    },
    createNewExclusion() {
      this.showNewExclusionDialog = true;
    },
    updateExclusionDialog(exclusion) {
      this.exclusionToUpdate = exclusion;
      this.showUpdateExclusionDialog = true;
    },
    deleteExclusionDialog(exclusion) {
      this.exclusionToDelete = exclusion;
      this.showDeleteExclusionDialog = true;
    },
    setDate(dateTime) {
      if (dateTime != null) return dateTime.split("T")[0];
    },
    async linkToInherited(entity, hierarchies) {
      let result = await this.goToInherited({
        entity: entity,
        hierarchies: hierarchies
      });
      if (!result) {
        this.showLinkWarning = true;
      }
    },
    mapFeeType(feeType) {
      return mapFeeTypeValueToDisplayName(feeType, this.billingFeeTypes);
    },
  },
};
</script>
