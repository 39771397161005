<template>
  <v-container>
    <delete-tier-dialog
      :showDialog.sync="showDeleteTierDialog"
      :tierToDelete.sync="tierToDelete"
    ></delete-tier-dialog>
    <add-update-tier-dialog
      :showDialog.sync="showAddUpdateTierDialog"
      :tierToModify.sync="tierToModify"
    ></add-update-tier-dialog>
    <v-row v-if="showTable">
      <v-col v-if="!inherited">
        <v-data-table
          fixed-header
          class="scrollable-table__large"
          v-if="hasTiers"
          :items="feeTiers"
          :headers="tierHeaders"
          item-key="id"
          disable-sort
          :hide-default-footer="feeTiers.length <= 10"
        >
          <template v-slot:[`item.aum`]="{ item }">
            {{ readableNumber(item.activationLevel) }}
          </template>
          <template v-slot:[`item.rateOrAmount`]="{ item }">
            <span v-if="item.billingTierType !== 'Percent'">
              <span class="teal--text text--darken-3">$</span>
              {{ item.rateOrAmount }}
            </span>
            <span v-else>
              {{ getPercentageFromNumber(item.rateOrAmount) }}
              <span class="teal--text text--darken-3">%</span>
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="modifyFeeTierDialog(item)" class="edit-icon">
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteFeeTierDialog(item)"
              class="delete-icon"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-else>
        <v-data-table
          fixed-header
          :class="{'scrollable-table__large' : true, 'show-as-disabled' : true}"
          v-if="hasTiers"
          :items="feeTiers"
          :headers="tierHeaders"
          item-key="id"
          disable-sort
          :hide-default-footer="feeTiers.length <= 10"
        >
          <template v-slot:[`item.aum`]="{ item }">
            {{ readableNumber(item.activationLevel) }}
          </template>
          <template v-slot:[`item.rateOrAmount`]="{ item }">
            <span v-if="item.billingTierType !== 'Percent'">
              <span class="teal--text text--darken-3">$</span>
              {{ item.rateOrAmount }}
            </span>
            <span v-else>
              {{ getPercentageFromNumber(item.rateOrAmount) }}
              <span class="teal--text text--darken-3">%</span>
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="modifyFeeTierDialog(item)"
              class="edit-icon"
              disabled
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteFeeTierDialog(item)"
              class="delete-icon"
              disabled
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense justify="end" v-if="!inherited">
      <v-btn
        class="mr-4"
        :disabled="!isFeeScheduleSelected"
        @click="openNewTierDialog"
        :color="colorTheme.button"
        dark
      >
        Add New Tier
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getPercentageFromNumber } from "@/utils/billing-records.js";
import AddUpdateTierDialog from "./dialogs/tiers/AddUpdateTier.vue";
import DeleteTierDialog from "./dialogs/tiers/DeleteTier.vue";

export default {
  components: {
    AddUpdateTierDialog,
    DeleteTierDialog
  },
  data: () => ({
    showAddUpdateTierDialog: false,
    showDeleteTierDialog: false,
    tierToDelete: null,
    tierToModify: null,
    defaultTier: {
      isPercent: true,
      amount: null,
      activationLevel: null
    },
    originalTier: {
      isPercent: true,
      amount: null,
      activationLevel: null
    },
    editedTier: {
      isPercent: true,
      amount: null,
      activationLevel: null
    },
    tierHeaders: [
      {
        text: "Starting AUM Basis",
        value: "aum",
        align: "end"
      },
      {
        text: "Rate or Amount",
        value: "rateOrAmount",
        align: "end"
      },
      {
        text: "Type",
        value: "billingTierType"
      },
      {
        text: "Actions",
        value: "actions",
        align: "center"
      }
    ]
  }),
  computed: {
    ...mapState("feeSchedule", ["inherited"]),
    ...mapState("feeTiers", ["feeTiers", "selectedFee"]),
    ...mapState("userConfig", ["colorTheme"]),
    isFeeScheduleSelected() {
      return this.selectedFee === null ? false : true;
    },
    showTable() {
      if (this.selectedFee) {
        return true;
      } else {
        return false;
      }
    },
    hasTiers() {
      return this.feeTiers.length > 0;
    }
  },
  methods: {
    readableNumber(number) {
      const numStr = number.toString().split("").reverse().join("");
      let readable = "";
      let i = 0;
      while (i < numStr.length) {
        if (i > 0 && i % 3 == 0) {
          if (numStr[i - 1] !== ".") {
            readable += ",";
          }
        }
        readable += numStr[i];
        i++;
      }
      return readable.split("").reverse().join("");
    },
    openNewTierDialog() {
      this.showAddUpdateTierDialog = true;
    },
    modifyFeeTierDialog(feeTier) {
      this.tierToModify = feeTier;
      this.showAddUpdateTierDialog = true;
    },
    deleteFeeTierDialog(feeTier) {
      this.tierToDelete = feeTier;
      this.showDeleteTierDialog = true;
    },
    getPercentageFromNumber(number) {
      return getPercentageFromNumber(number);
    }
  }
};
</script>

<style scoped>
.show-as-disabled {
  color: grey;
}
</style>
