<template>
  <v-dialog v-model="show" persistent max-width="750px" @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span class="headline"> New Standard Fee Schedule </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-card-text>
        <schedule-form
          ref="scheduleFormComponent"
          :formValidation.sync="valid"
          :name.sync="name"
          :feeType.sync="feeType"
          :discountType.sync="discountType"
          :aumBasisType.sync="aumBasisType"
          :monthlyMinimumFee.sync="monthlyMinimumFee"
          :scheduleFormShow="show"
        ></schedule-form>
      </v-card-text>
      <v-card-actions>
        <v-btn dark :color="colorTheme.button" @click="close">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :dark="valid"
          :color="colorTheme.button"
          @click="confirm"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import amplitude from "amplitude-js"
import ScheduleForm from "@/components/form/ScheduleForm.vue";

export default {
  components: {
    ScheduleForm
  },
  props: ["showDialog"],
  emits: ["update:showDialog", "selectNewSchedule"],
  data: () => ({
    valid: false,
    name: "",
    discountType: "",
    aumBasisType: "",
    feeType: "",
    monthlyMinimumFee: 0,
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show() {
      return this.showDialog;
    }
  },
  methods: {
    ...mapActions("modifySchedule", ["addAndAssignFeeSchedule"]),
    async confirm() {
      if (this.validate()) {
        try {
          amplitude.getInstance().logEvent(`Fee Assignment - Assign/Update Fees [New Fee Schedule Created]`);
          let newFee = await this.addAndAssignFeeSchedule({
            userName: "Add & Assign Fee Schedule TEST",
            name: this.name,
            billingDiscountType: this.discountType,
            billingAumBasisType: this.aumBasisType,
            billingFeeType: this.feeType,
            monthlyMinimumFee: Number(this.monthlyMinimumFee)
          });
          this.$emit("selectNewSchedule", newFee);
        } catch (err) {
          console.log(err);
        }
      }
      this.close();
    },
    close() {
      this.$refs.scheduleFormComponent.reset();
      this.$emit("update:showDialog", false);
    },
    validate() {
      return this.$refs.scheduleFormComponent.validate();
    },
    resetValidation() {
      this.$refs.scheduleFormComponent.resetValidation();
    },
  }
};
</script>
