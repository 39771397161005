<template>
  <confirmation-dialog
    :showDialog.sync="show"
    message="Are you sure you want to delete this exclusion?"
    @confirm="confirm"
    @cancel="cancel"
  ></confirmation-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog
  },
  props: ["showDialog", "exclusion"],
  emits: ["update:showDialog", "update:exclusion", "refresh"],
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    }
  },
  methods: {
    ...mapActions("exclusions", ["deleteExclusion"]),
    async confirm() {
      if (this.exclusion !== null) {
        try {
          await this.deleteExclusion({
            id: this.exclusion.id
          });
        } catch (err) {
          console.log(err);
        }
      }
      this.$emit("refresh");
      this.cancel();
    },
    cancel() {
      this.$emit("update:showDialog", false);
      this.$emit("update:exclusion", null);
    }
  }
};
</script>
