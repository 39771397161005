<template>
  <div>
    <running-dialog
      message="Updating properties. Please wait..."
      :showDialog.sync="saveToggle"
    ></running-dialog>
    <success-dialog
      message="Exclusion updated successfully"
      :showDialog.sync="updateSuccess"
    ></success-dialog>
    <error-dialog
      message="Failed to update exclusion! Try again"
      :showDialog.sync="updateError"
    ></error-dialog>
    <v-dialog @keydown.esc="cancel" v-model="show" persistent max-width="750px">
      <v-card>
        <v-card-title>
          <span class="headline"> Edit Exclusion </span>
          <span class="right-corner">
            <v-icon @click="cancel" class="exit-icon"> mdi-close </v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="5">
                  <v-switch
                    v-model="percentOrAmountSwitch"
                    :label="percentOrAmountLabel"
                    :color="colorTheme.toggle"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="2"></v-col>
                <v-col cols="12" md="5">
                  <v-checkbox
                    label="Expiration Date"
                    v-model="expDate"
                    v-on:change="hideShow"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="percentOrAmount"
                    :label="percentOrAmountLabel"
                    dense
                    :rules="percentOrAmountRules"
                    required
                    :prefix="flatDollar"
                    :suffix="percentBased"
                    :color="colorTheme.textField"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2"></v-col>
                <v-col cols="12" md="5">
                  <input
                    type="date"
                    v-model="date"
                    v-if="expDate"
                    min="1960-01-01"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-flex>
                    <v-select
                      chips
                      label="Fee Type"
                      v-model="feeType"
                      :items="billingFeeTypes"
                      item-text="displayName"
                      item-value="value"
                      :rules="rules.feeType"
                      required
                      :color="colorTheme.dropDown"
                      :item-color="colorTheme.dropDownItem"
                    >
                    </v-select>
                  </v-flex>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="itemsTicker"
                    :loading="isLoadingTicker"
                    :search-input.sync="searchTicker"
                    :filter="customFilter"
                    hide-no-data
                    single-line
                    hide-details
                    clearable
                    item-value="id"
                    item-text="ItemName"
                    label="Search by Ticker"
                    append-icon="mdi-database-search"
                    @change="selectNew"
                    return-object
                    :color="colorTheme.autocomplete"
                  >
                  </v-autocomplete>
                  <v-card-text v-if="minimumCharactersTicker">
                    Please type at least two characters
                  </v-card-text>
                  <v-card-text v-else-if="maximumCharactersTicker">
                    You've reached maximum number of characters
                  </v-card-text>
                  <v-card-text v-else-if="noResultsTicker">
                    No results...
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="itemsSecurityName"
                    :loading="isLoadingSecurityName"
                    :search-input.sync="searchSecurityName"
                    :filter="customFilter"
                    hide-no-data
                    single-line
                    hide-details
                    clearable
                    item-value="id"
                    item-text="ItemName"
                    label="Search by Security Name"
                    append-icon="mdi-database-search"
                    @change="selectNew"
                    return-object
                    :color="colorTheme.autocomplete"
                  >
                  </v-autocomplete>
                  <v-card-text v-if="minimumCharactersSecurityName">
                    Please type at least two characters
                  </v-card-text>
                  <v-card-text v-else-if="maximumCharactersSecurityName">
                    You've reached maximum number of characters
                  </v-card-text>
                  <v-card-text v-else-if="noResultsSecurityName">
                    No results...
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="itemsCusip"
                    :loading="isLoadingCusip"
                    :search-input.sync="searchCusip"
                    :filter="customFilter"
                    hide-no-data
                    single-line
                    hide-details
                    clearable
                    item-value="id"
                    item-text="ItemName"
                    label="Search by CUSIP"
                    append-icon="mdi-database-search"
                    @change="selectNew"
                    return-object
                    :color="colorTheme.autocomplete"
                  >
                  </v-autocomplete>
                  <v-card-text v-if="minimumCharactersCusip">
                    Please type at least two characters
                  </v-card-text>
                  <v-card-text v-else-if="maximumCharactersCusip">
                    You've reached maximum number of characters
                  </v-card-text>
                  <v-card-text v-else-if="noResultsCusip">
                    No results...
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-if="securityEntity.length > 0">
                <v-col cols="12">
                  <v-data-table
                    fixed-header
                    class="scrollable-table__medium"
                    :headers="securityHeaders"
                    :items="securityEntity"
                    item-key="id"
                    dense
                    hide-default-footer
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small @click="removeSecurityEntity(item)">
                        mdi-link-variant-off
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :style="colorTheme.textColor"
            :color="colorTheme.buttonWhite"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :dark="valid && securityEntity.length > 0 && validDate"
            :color="colorTheme.button"
            @click="confirm"
            :disabled="!valid || securityEntity.length == 0 || !validDate"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue"
import {
  minimumCharactersText,
  maximumCharactersText,
  noResultsText,
} from "@/utils/search-texts.js";

export default {
  components: {
    RunningDialog,
    ErrorDialog,
    SuccessDialog,
  },
  props: ["showDialog", "exclusionToUpdate"],
  emits: ["update:showDialog", "update:exclusionToUpdate", "refresh"],
  data: () => ({
    updateError: false,
    updateSuccess: false,
    saveToggle: false,
    expDate: false,
    securityEntity: [],
    valueLimit: 100,
    entriesTicker: [],
    entriesSecurityName: [],
    entriesCusip: [],
    isLoadingTicker: false,
    isLoadingCusip: false,
    isLoadingSecurityName: false,
    searchTicker: null,
    searchCusip: null,
    searchSecurityName: null,
    numberOfLoadingsTicker: 0,
    numberOfLoadingsCusip: 0,
    numberOfLoadingsSecurityName: 0,
    date: null,
    valid: false,
    percentOrAmountSwitch: false,
    percentOrAmount: 100,
    feeType: null,
    rules: {
      percent: [
        (v) =>
          /\d+\.?\d*/.test(v) ||
          "a percentage rate between 0 and 100 is required",
        (v) => v >= 0 || "percentage rate must be positive",
        (v) => v <= 100 || "percentage rate cannot exceed 100%"
      ],
      amount: [
        (v) => /\d+\.?\d*/.test(v) || "flat amount must be a number",
        (v) => v >= 0 || "flat amount must be positive"
      ],
      feeType: [(v) => v != null || "Fee Type is required"]
    },
    securityHeaders: [
      {
        text: "Ticker",
        value: "ticker",
        align: "start"
      },
      {
        text: "Security Name",
        value: "securityName",
        align: "center"
      },
      {
        text: "CUSIP",
        value: "cusip",
        align: "center"
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapState("databaseSearch", ["minSearchLength"]),
    ...mapState("feeSchedule", ["billingFeeTypes"]),
    ...mapState("exclusionSearch", ["securityEntities"]),
    ...mapState("userConfig", ["colorTheme"]),
    validDate() {
      if (!this.expDate) return true;
      else {
        if (!this.date) return false;
        else if (this.date.length > 10) return false;
        else {
          let year = Number(this.date.slice(0, 4));
          if (year < 1960) return false;
          else return true;
        }
      }
    },
    itemsTicker() {
      return this.entriesTicker.map((entry) => {
        const proposedItem =
          "[" +
          entry.ticker +
          "]" +
          " [" +
          entry.securityName +
          "]" +
          " [" +
          entry.cusip +
          "]";

        const ItemName =
          proposedItem.length > this.valueLimit
            ? proposedItem.slice(0, this.valueLimit) + "..."
            : proposedItem;

        return Object.assign({}, entry, { ItemName });
      });
    },
    itemsSecurityName() {
      return this.entriesSecurityName.map((entry) => {
        const proposedItem =
          "[" +
          entry.ticker +
          "]" +
          " [" +
          entry.securityName +
          "]" +
          " [" +
          entry.cusip +
          "]";

        const ItemName =
          proposedItem.length > this.valueLimit
            ? proposedItem.slice(0, this.valueLimit) + "..."
            : proposedItem;

        return Object.assign({}, entry, { ItemName });
      });
    },
    itemsCusip() {
      return this.entriesCusip.map((entry) => {
        const proposedItem =
          "[" +
          entry.ticker +
          "]" +
          " [" +
          entry.securityName +
          "]" +
          " [" +
          entry.cusip +
          "]";

        const ItemName =
          proposedItem.length > this.valueLimit
            ? proposedItem.slice(0, this.valueLimit) + "..."
            : proposedItem;

        return Object.assign({}, entry, { ItemName });
      });
    },
    show() {
      return this.showDialog;
    },
    percentOrAmountLabel() {
      return this.percentOrAmountSwitch ? "Amount" : "Percentage";
    },
    percentOrAmountRules() {
      return this.percentOrAmountSwitch
        ? this.rules.amount
        : this.rules.percent;
    },
    flatDollar() {
      if (this.percentOrAmountSwitch) {
        return "$";
      } else {
        return "";
      }
    },
    percentBased() {
      if (!this.percentOrAmountSwitch) {
        return "%";
      } else {
        return "";
      }
    },
    minimumCharactersTicker() {
      return minimumCharactersText(this.isLoadingTicker, this.searchTicker, this.minSearchLength);
    },
    maximumCharactersTicker() {
      return maximumCharactersText(this.searchTicker, 100);
    },
    noResultsTicker() {
      return noResultsText(
        this.isLoadingTicker,
        this.searchTicker,
        this.itemsTicker
      );
    },
    minimumCharactersCusip() {
      return minimumCharactersText(this.isLoadingCusip, this.searchCusip, 2);
    },
    maximumCharactersCusip() {
      return maximumCharactersText(this.searchCusip, 100);
    },
    noResultsCusip() {
      return noResultsText(
        this.isLoadingCusip,
        this.searchCusip,
        this.itemsCusip
      );
    },
    minimumCharactersSecurityName() {
      return minimumCharactersText(
        this.isLoadingSecurityName,
        this.searchSecurityName,
        2
      );
    },
    maximumCharactersSecurityName() {
      return maximumCharactersText(this.searchSecurityName, 100);
    },
    noResultsSecurityName() {
      return noResultsText(
        this.isLoadingSecurityName,
        this.searchSecurityName,
        this.itemsSecurityName
      );
    },
  },
  watch: {
    async exclusionToUpdate() {
      if (this.exclusionToUpdate != null) {
        if (this.exclusionToUpdate.exclusionPercentage != null) {
          this.percentOrAmountSwitch = false;
          this.percentOrAmount =
            this.exclusionToUpdate.exclusionPercentage * 100;
        } else {
          this.percentOrAmountSwitch = true;
          this.percentOrAmount = this.exclusionToUpdate.exclusionAmount;
        }
        if (this.exclusionToUpdate.expirationDate != null) {
          this.expDate = true;
        } else {
          this.expDate = false;
        }
        if (this.exclusionToUpdate.expirationDate != null)
          this.date = this.exclusionToUpdate.expirationDate.split("T")[0];
        this.feeType = this.exclusionToUpdate.billingFeeType;
        await this.findBillingSecurityEntity({
          ids: [this.exclusionToUpdate.billingSecurityEntityId],
          cusip: "",
          ticker: "",
          securityName: ""
        });
        this.securityEntity = this.securityEntities;
      }
    },
    searchTicker(val) {
      if (val !== null && val !== undefined && val.length >= this.minSearchLength) {
        this.filterByTicker(val);
      } else {
        this.entriesTicker = [];
      }
    },
    searchCusip(val) {
      if (val !== null && val !== undefined && val.length >= this.minSearchLength) {
        this.filterByCusip(val);
      } else {
        this.entriesCusip = [];
      }
    },
    searchSecurityName(val) {
      if (val !== null && val !== undefined && val.length >= this.minSearchLength) {
        this.filterBySecurityName(val);
      } else {
        this.entriesSecurityName = [];
      }
    },
  },
  methods: {
    ...mapActions("exclusions", ["updateExclusion"]),
    ...mapActions("exclusionSearch", ["findBillingSecurityEntity"]),
    customFilter() {
      return true;
    },
    async filterByTicker(filter) {
      this.numberOfLoadingsTicker++;
      this.isLoadingTicker = true;
      try {
        await this.findBillingSecurityEntity({
          ids: [],
          cusip: "",
          ticker: filter,
          securityName: ""
        });
      } catch (err) {
        console.log(err);
      }
      this.entriesTicker = this.securityEntities;
      this.numberOfLoadingsTicker--;
      if (this.numberOfLoadingsTicker == 0) {
        this.isLoadingTicker = false;
      }
    },
    async filterByCusip(filter) {
      this.numberOfLoadingsCusip++;
      this.isLoadingCusip = true;
      try {
        await this.findBillingSecurityEntity({
          ids: [],
          cusip: filter,
          ticker: "",
          securityName: ""
        });
      } catch (err) {
        console.log(err);
      }
      this.entriesCusip = this.securityEntities;
      this.numberOfLoadingsCusip--;
      if (this.numberOfLoadingsCusip == 0) {
        this.isLoadingCusip = false;
      }
    },
    async filterBySecurityName(filter) {
      this.numberOfLoadingsSecurityName++;
      this.isLoadingSecurityName = true;
      try {
        await this.findBillingSecurityEntity({
          ids: [],
          cusip: "",
          ticker: "",
          securityName: filter
        });
      } catch (err) {
        console.log(err);
      }
      this.entriesSecurityName = this.securityEntities;
      this.numberOfLoadingsSecurityName--;
      if (this.numberOfLoadingsSecurityName == 0) {
        this.isLoadingSecurityName = false;
      }
    },
    selectNew(val) {
      this.securityEntity = [val];
      this.clearEntries();
    },
    clearEntries() {
      this.entriesTicker = [];
      this.entriesSecurityName = [];
      this.entriesCusip = [];
    },
    removeSecurityEntity() {
      this.securityEntity = [];
    },
    hideShow() {
      if (!this.expDate) this.date = null;
    },
    async confirm() {
      this.saveToggle = true;
      if (this.validate()) {
        let percent = null;
        let amount = null;
        let securityEntityId = null;
        if (!this.percentOrAmountSwitch) {
          percent = this.percentOrAmount;
        } else {
          amount = this.percentOrAmount;
        }
        if (this.securityEntity.length > 0)
          securityEntityId = this.securityEntity[0].id;
        try {
          let result = await this.updateExclusion({
            id: this.exclusionToUpdate.id,
            userName: "UI",
            billingSecurityEntityId: securityEntityId,
            exclusionPercentage: percent,
            exclusionAmount: amount,
            expirationDate: this.date,
            billingFeeType: this.feeType
          });
          if (result) {
            this.updateSuccess = true;
          } else {
            this.updateError = true;
          }
        } catch (err){
          console.log(err);
          this.updateError = true;
        }
      }
      this.saveToggle = false;
      this.$emit("refresh");
      this.cancel();
    },
    cancel() {
      this.clearEntries();
      this.$emit("update:showDialog", false);
      this.$emit("update:exclusionToUpdate", null);
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>
