<template>
  <v-container v-if="shouldValidate" class="mt-0">
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-card-text v-if="inherited">
          View only. To edit this Fee Schedule click the "Inherited From" link
          above to go to the correct entity.
        </v-card-text>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Fee Schedule Name"
            v-model="formEdit.feeScheduleName"
            :rules="rules.name"
            required
            :color="colorTheme.textField"
            :disabled="inherited"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            label="Discount Type"
            v-model="formEdit.discountType"
            :rules="rules.discountType"
            required
            :items="billingDiscountTypes"
            item-text="displayName"
            item-value="value"
            :color="colorTheme.dropDown"
            :item-color="colorTheme.dropDownItem"
            :disabled="inherited"
          ></v-select>
          <v-text-field
            label="Monthly Minimum"
            v-model="formEdit.monthlyMinimumFee"
            prefix="$"
            :rules="rules.monthlyMinimumFee"
            required
            :color="colorTheme.dropDown"
            :disabled="inherited"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="8">
          <v-select
            label="AUM Basis"
            v-model="formEdit.aumBasis"
            :rules="rules.aumBasis"
            required
            :items="filteredBillingAumBasisTypes"
            item-text="displayName"
            item-value="value"
            :color="colorTheme.dropDown"
            :item-color="colorTheme.dropDownItem"
            :disabled="inherited"
          ></v-select>
          <v-select
            label="Fee Type"
            v-model="formEdit.feeType"
            :rules="rules.feeType"
            required
            :items="billingFeeTypes"
            item-text="displayName"
            item-value="value"
            :color="colorTheme.dropDown"
            :item-color="colorTheme.dropDownItem"
            :disabled="inherited"
          ></v-select>
        </v-col>
      </v-row>
      <v-card-actions v-if="!disableButtons">
        <v-spacer></v-spacer>
        <v-btn
          :style="colorTheme.textColor"
          :color="colorTheme.buttonWhite"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn dark :color="colorTheme.button" @click="save">
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { filterAumBasisTypes } from "@/utils/aum-types";

export default {
  data: () => ({
    valid: false,
    rules: {
      name: [(v) => !!v || "Name is required"],
      discountType: [(v) => !!v || "Discount Type is required"],
      aumBasis: [(v) => !!v || "Aum Basis is required"],
      feeType: [(v) => !!v || " Fee Type is required"],
      monthlyMinimumFee: [
        (v) => /\d+/.test(v) || "A number required",
        (v) => v >= 0 || "Positive number required"
      ]
    },
    formDefault: {
      feeScheduleName: "",
      discountType: "",
      aumBasis: "",
      feeType: "",
      monthlyMinimumFee: 0
    },
    formOriginal: {
      feeScheduleName: "",
      discountType: "",
      aumBasis: "",
      feeType: "",
      monthlyMinimumFee: 0
    },
    formEdit: {
      feeScheduleName: "",
      discountType: "",
      aumBasis: "",
      feeType: "",
      monthlyMinimumFee: 0
    },
  }),
  computed: {
    ...mapState("feeSchedule", [
      "billingDiscountTypes",
      "billingAumBasisTypes",
      "billingFeeTypes",
      "inherited",
    ]),
    ...mapState("hierarchyMode", ["hierarchyTag"]),
    ...mapState("feeTiers", ["selectedFee"]),
    ...mapState("userConfig", ["colorTheme"]),
    filteredBillingAumBasisTypes() {
      return filterAumBasisTypes(this.hierarchyTag.val, this.billingAumBasisTypes);
    },
    shouldValidate() {
      //Used for showing form
      if (this.selectedFee) {
        return true;
      } else {
        return false;
      }
    },
    formIsNotChanged() {
      if (this.formOriginal && this.formEdit) {
        const original = this.formOriginal;
        const modified = this.formEdit;
        let notChanged = true;
        if (
          original.feeScheduleName !== modified.feeScheduleName ||
          original.discountType !== modified.discountType ||
          original.aumBasis !== modified.aumBasis ||
          original.feeType !== modified.feeType ||
          Number(original.monthlyMinimumFee) !== Number(modified.monthlyMinimumFee)
        ) {
          notChanged = false;
        }
        return notChanged;
      }
      return true;
    },
    disableButtons() {
      if (this.selectedFee) {
        if (!this.valid) {
          return true;
        } else {
          return this.formIsNotChanged;
        }
      }
      return true;
    },
  },
  watch: {
    selectedFee() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      if (this.selectedFee) {
        this.populateForm(this.selectedFee);
      }
    },
  },
  methods: {
    ...mapActions("modifySchedule", ["modifyFeeSchedule"]),
    async save() {
      try {
        await this.modifyFeeSchedule({
          userName: "ModifyFeeSchedule TEST",
          name: this.formEdit.feeScheduleName,
          billingDiscountType: this.formEdit.discountType,
          billingAumBasisType: this.formEdit.aumBasis,
          billingFeeType: this.formEdit.feeType,
          monthlyMinimumFee: Number(this.formEdit.monthlyMinimumFee)
        });
      } catch (err) {
        console.log(err);
      }
    },
    cancel() {
      this.formEdit = Object.assign({}, this.formOriginal);
    },
    resetForm() {
      this.formOriginal = Object.assign({}, this.formDefault);
      this.formEdit = Object.assign({}, this.formDefault);
    },
    populateForm(item) {
      this.formOriginal = {
        feeScheduleName: item.name,
        discountType: item.billingDiscountType,
        aumBasis: item.billingAumBasisType,
        feeType: item.billingFeeType,
        monthlyMinimumFee: item.minimum
      };
      this.formEdit = Object.assign({}, this.formOriginal);
    },
  },
};
</script>
