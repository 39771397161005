<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <fee-table></fee-table>
      </v-col>
      <v-col cols="12" md="6">
        <fee-overview></fee-overview>
      </v-col>
      <v-col cols="12" md="6">
        <the-tiers></the-tiers>
      </v-col>
      <v-col cols="12">
        <exclusion-table></exclusion-table>
      </v-col>
    </v-row>
    <error-dialog
      message="Fee schedules error! Try again."
      :showDialog.sync="feeSchedulesErrorDialog"
    ></error-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FeeTable from "@/components/fee-schedule/FeeTable.vue";
import FeeOverview from "@/components/fee-schedule/FeeOverview.vue";
import TheTiers from "@/components/fee-schedule/TheTiers.vue";
import ExclusionTable from "@/components/exclusions/ExclusionTable.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";

export default {
  props: ["hierarchy", "id"],
  data() {
    return {};
  },
  components: {
    FeeTable,
    FeeOverview,
    TheTiers,
    ExclusionTable,
    ErrorDialog
  },
  computed: {
    ...mapState("dialogs", ["feeSchedulesError"]),
    feeSchedulesErrorDialog: {
      get() {
        return this.feeSchedulesError;
      },
      set(newValue) {
        this.setFeeSchedulesError(newValue);
      }
    }
  },
  methods: {
    ...mapMutations("dialogs", ["setFeeSchedulesError"]),
    ...mapActions("feeSchedule", ["refreshSchedules"]),
    ...mapActions("browseEntities", ["setDefaultValues"]),
  },
  mounted: async function() {
    let result = await this.setDefaultValues({
      tabName: "FeeSchedules",
      hierarchy: this.hierarchy,
      id: this.id,
    });

    if (result) {
      await this.refreshSchedules(this.id);
    }
  },
};
</script>

<style>
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #26a69a !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #26a69a !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: #26a69a !important;
}
.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: r#26a69aed !important;
}
</style>
