<template>
  <confirmation-dialog
    :showDialog.sync="show"
    message="Are you sure you want to delete this schedule?"
    @confirm="confirm"
    @cancel="cancel"
  >
    <span v-if="entityType !== ''">
      All accounts in this {{ entityType }} will no longer get this fee schedule
    </span>
    <ShowAffectedEntities :feeSchedule.sync="feeSchedule" />
  </confirmation-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import ShowAffectedEntities from "@/components/fee-schedule/dialogs/schedule/ShowAffectedEntities.vue";

export default {
  components: {
    ConfirmationDialog,
    ShowAffectedEntities
  },
  props: ["showDialog", "feeSchedule", "billingEntityType"],
  emits: ["update:showDialog", "update:feeSchedule"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      },
    },
    entityType() {
      if (!this.billingEntityType) return "";
      if (this.billingEntityType.includes("FIRM")) {
        return "firm";
      }
      if (this.billingEntityType.includes("GROUP")) {
        return "group";
      }
      return "";
    }
  },
  methods: {
    ...mapActions("modifySchedule", ["deleteFeeSchedule"]),
    confirm() {
      let error = null;
      if (this.feeSchedule !== null) {
        try {
          this.deleteFeeSchedule({
            id: this.feeSchedule.id,
          });
        } catch (err) {
          error = err;
        }
      }
      if (error) {
        console.log(error);
      }
      this.cancel();
    },
    cancel() {
      this.$emit("update:showDialog", false);
      this.$emit("update:feeSchedule", null);
    },
  },
};
</script>
