<template>
  <v-dialog v-model="show" persistent max-width="600px" @keydown.esc="cancel">
    <v-card>
      <v-card-title>
        <span class="headline">{{ addOrUpdateLabel }} Tier</span>
        <span class="right-corner">
          <v-icon @click="cancel" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="editedItem.isPercent"
                  :label="percentLabel"
                  :color="colorTheme.toggle"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.amount"
                  :label="amountLabel"
                  dense
                  :rules="amountRules"
                  required
                  :prefix="flatDollar"
                  :suffix="percentBased"
                  :color="colorTheme.textField"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.activationLevel"
                  label="Starting Level"
                  dense
                  :rules="rules.activationLevel"
                  :readonly="!fixZeroLevel"
                  :hint="startingLevelHint"
                  persistent-hint
                  :color="colorTheme.textField"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :style="colorTheme.textColor"
          :color="colorTheme.buttonWhite"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          :dark="valid && uniqueTier()"
          :color="colorTheme.button"
          @click="confirm"
          :disabled="!valid || !uniqueTier()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getPercentageFromNumber } from "@/utils/billing-records.js";

export default {
  props: ["showDialog", "tierToModify"],
  emits: ["update:showDialog", "update:tierToModify"],
  data: () => ({
    valid: false,
    defaultItem: {
      isPercent: true,
      amount: 0,
      activationLevel: 0
    },
    editedItem: {
      isPercent: true,
      amount: 0,
      activationLevel: 0
    },
    rules: {
      amount: {
        percent: [
          (v) =>
            /\d+\.?\d*/.test(v) ||
            "a percentage rate between 0 and 100 is required",
          (v) => v >= 0 || "percentage rate must be positive",
          (v) => v <= 100 || "percentage rate cannot exceed 100%"
        ],
        flat: [
          (v) => /\d+\.?\d*/.test(v) || "flat amount must be a number",
          (v) => v >= 0 || "flat amount must be positive"
        ],
      },
      activationLevel: [
        (v) => /\d+/.test(v) || "A number required",
        (v) => v >= 0 || "Positive number required"
      ],
      // () => true || "Tier with same starting level already exists",
    }
  }),
  computed: {
    ...mapState("feeTiers", ["feeTiers"]),
    ...mapState("userConfig", ["colorTheme"]),
    flatDollar() {
      if (!this.editedItem.isPercent) {
        return "$";
      } else {
        return "";
      }
    },
    percentBased() {
      if (this.editedItem.isPercent) {
        return "%";
      } else {
        return "";
      }
    },
    show() {
      return this.showDialog;
    },
    fixZeroLevel() {
      if (this.isModification && this.tierToModify.activationLevel === 0) {
        return false;
      }
      return this.feeTiers.length > 0;
    },
    isModification() {
      return this.tierToModify !== null;
    },
    addOrUpdateLabel() {
      return this.isModification ? "Update" : "Add";
    },
    percentLabel() {
      return this.editedItem.isPercent ? "Percent" : "Flat";
    },
    amountLabel() {
      return this.percentLabel + " Amount";
    },
    amountRules() {
      return this.editedItem.isPercent
        ? this.rules.amount.percent
        : this.rules.amount.flat;
    },
    startingLevelHint() {
      if (!this.fixZeroLevel) {
        return "The first tier must start at 0";
      } else if (!this.uniqueTier()) {
        return "Tier with that starting level already exists!";
      } else {
        return "";
      }
    }
  },
  watch: {
    fixZeroLevel() {
      if (!this.fixZeroLevel) {
        this.editedItem.amount = 0;
      }
    },
    tierToModify() {
      if (this.isModification) {
        const isPercent = this.percentOrFlatToIsPercent(
          this.tierToModify.billingTierType
        );
        const amount = isPercent
          ? this.getPercentageFromNumber(this.tierToModify.rateOrAmount)
          : this.tierToModify.rateOrAmount;
        this.editedItem.isPercent = isPercent;
        this.editedItem.amount = amount;
        this.editedItem.activationLevel = this.tierToModify.activationLevel;
      }
    }
  },
  methods: {
    ...mapActions("feeTiers", ["addFeeTier", "modifyFeeTier"]),
    percentToNumber(percent) {
      return percent / 100;
    },
    percentOrFlat(isPercent) {
      return isPercent ? "Percent" : "Flat";
    },
    percentOrFlatToIsPercent(text) {
      if (text === "Percent") {
        return true;
      } else {
        return false;
      }
    },
    uniqueTier() {
      for (let i = 0; i < this.feeTiers.length; i++) {
        if (this.isModification) {
          if (
            this.editedItem.activationLevel ==
              this.feeTiers[i].activationLevel &&
            this.editedItem.activationLevel != this.tierToModify.activationLevel
          ) {
            return false;
          }
        } else {
          if (
            this.editedItem.activationLevel == this.feeTiers[i].activationLevel
          )
            return false;
        }
      }
      return true;
    },
    async confirm() {
      if (this.validate()) {
        const rateOrAmount = this.editedItem.isPercent
          ? this.percentToNumber(this.editedItem.amount)
          : this.editedItem.amount;
        try {
          if (!this.isModification) {
            await this.addFeeTier({
              userName: "AddTier TEST",
              billingTierType: this.percentOrFlat(this.editedItem.isPercent),
              activationLevel: parseFloat(this.editedItem.activationLevel),
              rateOrAmount: parseFloat(rateOrAmount)
            });
          } else {
            await this.modifyFeeTier({
              id: this.tierToModify.id,
              billingFeeDefinitionsId: this.tierToModify.billingFeeDefinitionsId,
              billingTierType: this.percentOrFlat(this.editedItem.isPercent),
              activationLevel: parseFloat(this.editedItem.activationLevel),
              rateOrAmount: parseFloat(rateOrAmount)
            });
          }
        } catch (err){
          console.log(err);
        }
      }
      this.cancel();
    },
    cancel() {
      this.reset();
      if (this.isModification) {
        this.$emit("update:tierToModify", null);
      }
      this.$emit("update:showDialog", false);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.editedItem = Object.assign({}, this.defaultItem);
      // this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getPercentageFromNumber(number) {
      return getPercentageFromNumber(number);
    },
  }
};
</script>
