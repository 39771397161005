<template>
  <v-dialog v-model="show" persistent max-width="850px" @keydown.esc="close">
    <v-stepper v-model="step">
      <v-card-title>
        <span class="headline"> New Special Fee Schedule </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-stepper-header>
        <!-- Steps for creating a deal fee schedule -->
        <v-stepper-step
          v-if="formPicker.hierarchyMode !== 'MODEL_MANAGER'"
          :complete="step > 1"
          step="1"
          :color="colorTheme.stepper"
        >
          Choose a Manager/Model
        </v-stepper-step>
        <v-stepper-step
          v-if="formPicker.hierarchyMode === 'MODEL_MANAGER'"
          :complete="step > 1"
          step="1"
          :color="colorTheme.stepper"
        >
          Choose Firm/Account/Group
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 2"
          step="2"
          :color="colorTheme.stepper"
        >
          Define a Fee Schedule
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :color="colorTheme.stepper">
          Review and Create
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-combobox
                      :items="pickItems"
                      :loading="formPicker.isLoading"
                      :search-input.sync="search"
                      :filter="customFilter"
                      item-text="itemName"
                      hide-no-data
                      single-line
                      hide-details
                      clearable
                      @click:clear="resetPickForm"
                      item-value="id"
                      label="Search"
                      append-icon="mdi-magnify"
                      return-object
                      :color="colorTheme.comboBox"
                      @change="selectEntity"
                      @keypress="checkLength"
                    >
                    </v-combobox>
                    <v-card-text v-if="minimumCharacters">
                      Please type at least two characters
                    </v-card-text>
                    <v-card-text v-else-if="maximumCharacters">
                      You've reached maximum number of characters
                    </v-card-text>
                    <v-card-text v-else-if="noResultsMessage">
                      No results...
                    </v-card-text>
                  </v-col>
                </v-row>
                <div v-if="isSelected">
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        label="ID"
                        v-model="formPicker.selectedEntity.id"
                        outlined
                        readonly
                        :color="colorTheme.textField"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="8">
                      <v-text-field
                        label="Name"
                        v-model="formPicker.selectedEntity.name"
                        outlined
                        readonly
                        :color="colorTheme.textField"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        label="Type"
                        v-model="formPicker.selectedEntity.type"
                        outlined
                        readonly
                        :color="colorTheme.textField"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn dark :color="colorTheme.button" @click="back(1)">
                Close
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :dark="isSelected"
                :color="colorTheme.button"
                @click="nextStep(1)"
                :disabled="!isSelected"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card>
            <v-card-text>
              <schedule-form
                ref="scheduleFormComponent"
                :formValidation.sync="formSchedule.valid"
                :name.sync="formSchedule.edit.name"
                :feeType.sync="formSchedule.edit.feeType"
                :discountType.sync="formSchedule.edit.discountType"
                :aumBasisType.sync="formSchedule.edit.aumBasisType"
                :monthlyMinimumFee.sync="formSchedule.edit.monthlyMinimumFee"
              ></schedule-form>
            </v-card-text>
            <v-card-actions>
              <v-btn dark :color="colorTheme.button" @click="back(2)">
                Back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :dark="formSchedule.valid"
                :color="colorTheme.button"
                @click="nextStep(2)"
                :disabled="!formSchedule.valid"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card>
            <v-card-text>
              <v-container v-if="showLoader">
                <v-row justify="center">
                  <v-progress-circular
                    :size="64"
                    :color="colorTheme.progress"
                    indeterminate
                  >
                  </v-progress-circular>
                </v-row>
                <v-row justify="center" class="mt-8">
                  <div>Creating a Deal, please wait...</div>
                </v-row>
              </v-container>
              <v-container v-else>
                <v-container>
                  <v-row justify="center">
                    <div class="text-h5">
                      A Deal will be made between Entities
                    </div>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-row no-gutters>
                        <v-col>
                          <v-text-field
                            label="Entity 1"
                            v-model="billingEntity.name"
                            outlined
                            readonly
                            :color="colorTheme.textField"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <v-text-field
                            label="ID"
                            v-model="billingEntity.id"
                            outlined
                            readonly
                            :color="colorTheme.textField"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-divider vertical inset></v-divider>
                    <v-col>
                      <v-row no-gutters>
                        <v-col>
                          <v-text-field
                            label="Entity 2"
                            v-model="formPicker.selectedEntity.name"
                            outlined
                            readonly
                            :color="colorTheme.textField"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <v-text-field
                            label="ID"
                            v-model="formPicker.selectedEntity.id"
                            outlined
                            readonly
                            :color="colorTheme.textField"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container>
                  <v-row justify="center">
                    <div class="text-h5">
                      ...with the following Fee Schedule
                    </div>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Fee Schedule Name"
                        v-model="formSchedule.edit.name"
                        readonly
                        outlined
                        :color="colorTheme.textField"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-layout>
                    <v-flex auto>
                      <v-select
                        label="Fee Type"
                        v-model="formSchedule.edit.feeType"
                        :items="billingFeeTypes"
                        item-text="displayName"
                        item-value="value"
                        readonly
                        outlined
                        :color="colorTheme.dropDown"
                        :item-color="colorTheme.dropDownItem"
                      ></v-select>
                    </v-flex>
                    <v-flex auto>
                      <v-select
                        label="Discount Type"
                        v-model="formSchedule.edit.discountType"
                        :items="billingDiscountTypes"
                        item-text="displayName"
                        item-value="value"
                        readonly
                        outlined
                        :color="colorTheme.dropDown"
                        :item-color="colorTheme.dropDownItem"
                      ></v-select>
                    </v-flex>
                    <v-flex auto>
                      <v-select
                        label="AUM Basis"
                        v-model="formSchedule.edit.aumBasisType"
                        :items="filteredBillingAumBasisTypes"
                        item-text="displayName"
                        item-value="value"
                        readonly
                        outlined
                        :color="colorTheme.dropDown"
                        :item-color="colorTheme.dropDownItem"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn dark :color="colorTheme.button" @click="back(3)">
                Back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn dark :color="colorTheme.button" @click="nextStep(3)">
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { filterAumBasisTypes } from "@/utils/aum-types";
//import {dealsModelSearchTypes, dealsAccountSearchTypes} from "@/store/modules/fee-schedules/pick-deal-entity.js";

import ScheduleForm from "@/components/form/ScheduleForm.vue";
import {
  lettersAndNumbersCheck,
  validCharacterCheck,
  maximumCharactersText,
  noResultsText
} from "@/utils/search-texts.js";
import amplitude from 'amplitude-js'

export default {
  components: {
    ScheduleForm
  },
  props: ["showDialog", "currentHierarchy"],
  emits: ["update:showDialog", "selectNewSchedule"],
  data: () => ({
    noResults: false,
    numberOfLoadings: 0,
    search: null,
    showLoader: false,
    formPicker: {
      hierarchyMode: "MODEL_MANAGER",
      types: [],
      valid: false,
      valueLimit: 50,
      isLoading: false,
      items: [],
      defaultSelectedEntity: {
        id: null,
        name: null,
        type: null
      },
      selectedEntity: {
        id: null,
        name: null,
        type: null
      }
    },
    formSchedule: {
      valid: false,
      default: {
        name: "",
        feeType: "",
        discountType: "",
        aumBasisType: "",
        monthlyMinimumFee: 0
      },
      edit: {
        name: "",
        feeType: "",
        discountType: "",
        aumBasisType: "",
        monthlyMinimumFee: 0
      }
    },
    step: 1
  }),
  computed: {
    ...mapState("databaseSearch", ["minSearchLength"]),
    ...mapState("feeSchedule", [
      "billingFeeTypes",
      "billingDiscountTypes",
      "billingAumBasisTypes",
      "billingEntity"
    ]),
    ...mapState("pickDealEntity", [
      "dealsModelSearchTypes",
      "dealsAccountSearchTypes"
    ]),
    ...mapState("hierarchyMode", ["hierarchyTag"]),
    ...mapState("userConfig", ["colorTheme"]),
    filteredBillingAumBasisTypes() {
      return filterAumBasisTypes(this.hierarchyTag.val, this.billingAumBasisTypes);
    },
    isSelected() {
      const id = this.formPicker.selectedEntity.id;
      return id != undefined && id !== null && id !== "";
    },
    pickItems() {
      return this.formPicker.items.map((entry) => {
        const proposedItem = entry.name + " [" + entry.type + "]";

        const itemName =
          proposedItem.length > this.formPicker.valueLimit
            ? proposedItem.slice(0, this.valueLimit) + "..."
            : proposedItem;

        return Object.assign({}, entry, { itemName });
      });
    },
    show() {
      return this.showDialog;
    },
    minimumCharacters() {
      return lettersAndNumbersCheck(
        this.formPicker.isLoading,
        this.search,
        this.minSearchLength
      );
    },
    maximumCharacters() {
      return maximumCharactersText(this.search, 100);
    },
    noResultsMessage() {
      return (
        noResultsText(this.formPicker.isLoading, this.search, this.pickItems) &&
        !this.isSelected &&
        this.noResults == true
      );
    },
  },
  watch: {
    search(val) {
      if (validCharacterCheck(val, this.minSearchLength)) {
        this.filterEntities(val);
      } else {
        this.formPicker.items = [];
      }
    }
  },
  methods: {
    ...mapMutations("searchEntities", ["cancelSearch"]),
    ...mapActions("searchEntities", ["searchEntitiesByListOfTypes"]),
    ...mapActions("modifySchedule", ["createDealFeeSchedule"]),
    customFilter() {
      return true;
    },
    async filterEntities(filter) {
      this.numberOfLoadings++;
      this.formPicker.isLoading = true;
      this.noResults = false;
      try {
        let result = await this.searchEntitiesByListOfTypes({
          filter: filter,
          types: this.formPicker.types,
        });
        if (result) {
          if (result.length == 0) {
            this.noResults = true;
          }
          this.formPicker.items = result;
        }
      } catch (err) {
        console.log(err);
      }
      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.formPicker.isLoading = false;
      }
    },
    selectEntity(val) {
      this.formPicker.selectedEntity = Object.assign({}, val);
    },
    resetPickForm() {
      this.cancelSearch();
      this.formPicker.selectedEntity = Object.assign(
        {},
        this.formPicker.defaultSelectedEntity
      );
      this.resetScheduleForm();
    },
    resetScheduleForm() {
      this.formSchedule.edit = Object.assign({}, this.formSchedule.default);
      this.$refs.scheduleFormComponent.resetValidation();
    },
    back(currStep) {
      switch (currStep) {
        case 1:
          this.close();
          break;
        case 2:
          this.step = 1;
          break;
        case 3:
          this.step = 2;
          break;
      }
      if (currStep > 1) {
        this.step = currStep - 1;
      }
    },
    nextStep(currStep) {
      switch (currStep) {
        case 1:
          this.step = 2;
          break;
        case 2:
          this.step = 3;
          break;
        case 3:
          this.confirm();
          break;
      }
    },
    async confirm() {
      try {
        this.showLoader = true;
        amplitude.getInstance().logEvent(`Fee Assignment - Assign/Update Fees [New Special Fee Schedule Created]`);
        let newFee = await this.createDealFeeSchedule({
          userName: "CreateDealFeeSchedule TEST",
          name: this.formSchedule.edit.name,
          billingFeeType: this.formSchedule.edit.feeType,
          billingDiscountType: this.formSchedule.edit.discountType,
          billingAumBasisType: this.formSchedule.edit.aumBasisType,
          billingEntityIdTwo: this.formPicker.selectedEntity.id,
          monthlyMinimumFee: Number(this.formSchedule.edit.monthlyMinimumFee)
        });
        this.$emit("selectNewSchedule", newFee);
        this.showLoader = false;
      } catch (err) {
        console.log(err);
      }
      this.close();
    },
    close() {
      this.$emit("update:showDialog", false);
      this.resetPickForm();
      this.resetScheduleForm();
      this.step = 1;
      this.search = "";
      this.formPicker.isLoading = false;
    },
    checkLength() {
      if (
        this.search !== undefined &&
        this.search !== null &&
        this.search.length >= 99
      ) {
        this.search = this.search.slice(0, 99);
      }
    }
  },
  mounted() {
    this.formPicker.hierarchyMode = this.currentHierarchy;
    this.formPicker.types =
      (this.currentHierarchy === "ACCOUNT_FIRM" ||
      this.currentHierarchy === "ACCOUNT_GROUP")
        ? this.dealsAccountSearchTypes
        : this.currentHierarchy === "MODEL_MANAGER"
        ? this.dealsModelSearchTypes
        : [];
  },
};
</script>
