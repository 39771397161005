<template>
  <div>
    <warning-dialog
      message="Something is wrong! Reload to avoid problems."
      :showDialog.sync="showLinkWarning"
    ></warning-dialog>
    <new-fee-schedule-dialog
      :showDialog.sync="showNewFeeScheduleDialog"
      @selectNewSchedule="selectNewSchedule"
    ></new-fee-schedule-dialog>
    <delete-fee-schedule-dialog
      :showDialog.sync="showDeleteScheduleDialog"
      :feeSchedule.sync="scheduleToDelete"
      :billingEntityType="billingEntity.type"
    ></delete-fee-schedule-dialog>
    <create-deal-fee-schedule-dialog
      :showDialog.sync="showCreateDealFeeScheduleDialog"
      :currentHierarchy="currentHierarchy"
      @selectNewSchedule="selectNewSchedule"
    ></create-deal-fee-schedule-dialog>
    <v-container>
      <v-row>
        <v-col>
          <v-btn
            class="mb-2 mr-2"
            :color="colorTheme.button"
            dark
            @click="newFeeScheduleDialog"
            :disabled="!isFeeTypeSelected"
          >
            New Standard Fee Schedule
          </v-btn>
          <v-btn
            class="mb-2 mr-2"
            :color="colorTheme.button"
            dark
            @click="createNewDealSchedule"
            :disabled="!isFeeTypeSelected"
          >
            New Special Fee Schedule
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-data-table
            fixed-header
            class="scrollable-table__large"
            :headers="feeTableHeaders"
            :items="visibleFees"
            :search="scheduleSearch"
            item-key="id"
            show-select
            single-select
            @item-selected="selectTableFee"
            v-model="selectedItems"
            :key="componentKey1"
            :footer-props="{
              'items-per-page-options': rowsPerPage,
            }"
            :items-per-page="25"
            :custom-sort="customSort"
          >
            <template v-if="visibleFees.length > 3" v-slot:top>
              <v-row class="mx-4">
                <v-text-field
                  v-model="scheduleSearch"
                  label="Search schedules"
                  :color="colorTheme.textField"
                ></v-text-field>
              </v-row>
            </template>
            <template v-slot:[`item.inheritedFrom`]="{ item }">
              <td>
                <v-card-text
                  v-if="getInheritance(item, 'inheritedFrom') === 'entity1'"
                  class="link-text"
                  @click="
                    linkToInherited(item.entity1, item.entity1Hierarchies)
                  "
                >
                  {{ item.entity1.name }}
                </v-card-text>
                <v-card-text
                  v-else-if="
                    getInheritance(item, 'inheritedFrom') === 'entity2'
                  "
                  class="link-text"
                  @click="
                    linkToInherited(item.entity2, item.entity2Hierarchies)
                  "
                >
                  {{ item.entity2.name }}
                </v-card-text>
              </td>
            </template>
            <template v-slot:[`item.inAssociationWith`]="{ item }">
              <td>
                <v-card-text
                  v-if="getInheritance(item, 'inAssociationWith') === 'entity1'"
                  class="link-text"
                  @click="
                    linkToInherited(item.entity1, item.entity1Hierarchies)
                  "
                >
                  {{ item.entity1.name }}
                </v-card-text>
                <v-card-text
                  v-else-if="
                    getInheritance(item, 'inAssociationWith') === 'entity2'
                  "
                  class="link-text"
                  @click="
                    linkToInherited(item.entity2, item.entity2Hierarchies)
                  "
                >
                  {{ item.entity2.name }}
                </v-card-text>
              </td>
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                v-if="isInherited(item)"
                :value="isSelected"
                @input="select($event)"
              >
                mdi-information-outline
              </v-simple-checkbox>
              <v-icon
                v-else
                class="edit-icon ml-1"
                small
                @click="select($event)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="!isInherited(item)"
                small
                @click="deleteFeeScheduleDialog(item)"
                class="delete-icon"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" :color="colorTheme.alert">
                {{ noDataText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NewFeeScheduleDialog from "./dialogs/schedule/NewFeeSchedule.vue";
import DeleteFeeScheduleDialog from "./dialogs/schedule/DeleteFeeSchedule.vue";
import CreateDealFeeScheduleDialog from "./dialogs/schedule/CreateDealFeeSchedule.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";

export default {
  components: {
    NewFeeScheduleDialog,
    DeleteFeeScheduleDialog,
    CreateDealFeeScheduleDialog,
    WarningDialog,
  },
  data: () => ({
    showLinkWarning: false,
    scheduleSearch: "",
    /*defaultItem: {
      feeScheduleName: "",
      discountType: "",
      aumBasis: "",
    },
    editedItem: {
      feeScheduleName: "",
      discountType: "",
      aumBasis: "",
    },*/
    showNewFeeScheduleDialog: false,
    showDeleteScheduleDialog: false,
    showCreateDealFeeScheduleDialog: false,
    //defaultFeeScheduleName: "",
    scheduleToDelete: null,
    selectedItems: [], //selected items in the data-table
    componentKey: 0,
    componentKey1: 0,
    feeTableHeaders: [
      {
        text: "Fee",
        value: "fee", //"billingFeeType",
        align: "start",
      },
      {
        text: "Name",
        value: "name",
        align: "start",
      },
      {
        text: "Inherited from",
        value: "inheritedFrom",
        align: "start",
      },
      {
        text: "In association with",
        value: "inAssociationWith",
        align: "start",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("feeSchedule", [
      "visibleFees",
      "billingEntity",
      "selectedFeeType",
      "visibleFees"
    ]),
    ...mapState("hierarchyMode", ["hierarchyTag", "hierarchyTags"]),
    ...mapState("userConfig", ["colorTheme", "rowsPerPage"]),
    noDataText() {
      return "No fees defined yet";
    },
    isFeeTypeSelected() {
      const isSelected = this.selectedFeeType.displayName === "" ? false : true;
      return isSelected;
    },
    feeScheduleName() {
      return this.billingEntity.name;
    },
    currentHierarchy() {
      return this.hierarchyTag.val;
    }
  },
  watch: {
    selectedFeeType() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.selectedItems = [];
      this.selectBillingFeeType({ type: this.selectedFeeType.value });
    },
  },
  methods: {
    ...mapActions("feeSchedule", ["selectBillingFeeType", "goToInherited"]),
    ...mapActions("feeTiers", ["selectFee"]),
    ...mapActions("hierarchyMode", ["setHierarchyTag"]),
    newFeeScheduleDialog() {
      this.showNewFeeScheduleDialog = true;
      //this.defaultFeeScheduleName = this.feeScheduleName + " " + this.selectedFeeType.displayName;
    },
    createNewDealSchedule() {
      this.showCreateDealFeeScheduleDialog = true;
    },
    deleteFeeScheduleDialog(schedule) {
      this.scheduleToDelete = schedule;
      this.showDeleteScheduleDialog = true;
    },
    isInherited(item) {
      let entity1 = item.entity1;
      let entity2 = item.entity2;
      let billingEntity = this.billingEntity;

      if (entity1 === null && entity2 === null) {
        return false;
      }
      if (entity1 !== null && entity2 === null) {
        return entity1.id !== billingEntity.id;
      }
      if (entity1 === null && entity2 !== null) {
        return entity2.id !== billingEntity.id;
      }
      if (entity1 !== null && entity2 !== null) {
        return (
          entity1.id !== billingEntity.id && entity2.id !== billingEntity.id
        );
      }
    },
    getInheritance(item, field) {
      let entity1 = item.entity1;
      let entity2 = item.entity2;
      let entity2Hierarchies = item.entity2Hierarchies;
      let billingEntity = this.billingEntity;
      let inheritedFrom = null;
      let inAssociationWith = null;

      if (entity1 !== null && entity2 === null) {
        inheritedFrom = entity1.id === this.billingEntity.id ? null : "entity1";
        inAssociationWith = null;
      } else if (entity1 === null && entity2 !== null) {
        inheritedFrom = entity2.id === this.billingEntity.id ? null : "entity2";
        inAssociationWith = null;
      } else if (entity1 !== null && entity2 !== null) {
        if (entity1.id === billingEntity.id) {
          inheritedFrom = null;
          inAssociationWith = "entity2";
        } else if (entity2.id === billingEntity.id) {
          inheritedFrom = null;
          inAssociationWith = "entity1";
        } else {
          if (entity2Hierarchies.indexOf(this.currentHierarchy) > -1) {
            inheritedFrom = "entity2";
            inAssociationWith = "entity1";
          } else {
            inheritedFrom = "entity1";
            inAssociationWith = "entity2";
          }
        }
      }
      if (field === "inheritedFrom") {
        return inheritedFrom;
      } else if (field === "inAssociationWith") {
        return inAssociationWith;
      } else {
        return null;
      }
    },
    selectNewSchedule(newFee) {
      let lastAddedElement = this.visibleFees.find(e => e.id === newFee.id);
      this.selectedItems = [lastAddedElement];
      let element = { item: lastAddedElement, value: {} };
      this.selectTableFee(element);
    },
    selectTableFee(payload) {
      let inherited = false;
      if (this.isInherited(payload.item)) {
        inherited = true;
      }
      this.selectFee({
        item: payload.item,
        value: payload.value,
        inherited: inherited,
      });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerender1() {
      this.componentKey1 += 1;
    },
    async linkToInherited(entity, hierarchies) {
      let result = await this.goToInherited({
        entity: entity,
        hierarchies: hierarchies
      });
      if (!result) {
        this.showLinkWarning = true;
      }
    },
    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        if (index[0] === 'inAssociationWith' || index[0] === 'inheritedFrom') {
          if (this.getInheritance(a, index[0]) === 'entity1') {
            return isDescending[0] ? b.entity1.name.localeCompare(a.entity1.name) : a.entity1.name.localeCompare(b.entity1.name);
          } else if (this.getInheritance(a, index[0]) === 'entity2') {
            return isDescending[0] ? b.entity2.name.localeCompare(a.entity2.name) : a.entity2.name.localeCompare(b.entity2.name);
          }
        } else if (index[0] === 'fee') {
          return isDescending[0] ? b.fee.localeCompare(a.fee) : a.fee.localeCompare(b.fee);    
        } else if (index[0] === 'name') {
          return isDescending[0] ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);    
        }
      });
      
      return items;
    }
  },
};
</script>
