<template>
  <div>
    <div>
      <span>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              :color="colorTheme.button"
              @click="showEntities"
            >
              <template v-if="show">mdi-eye</template>
              <template v-else>mdi-eye-off</template>
            </v-icon>
          </template>
          <span>
            <span v-if="show">Hide</span>
            <span v-else>Show</span>
            affected entities
          </span>
        </v-tooltip>
      </span>
    </div>
    <div v-if="show">
      <v-data-table
        :items="entities"
        item-key="id"
        :headers="entityHeaders"
        dense
        hide-default-footer
      >
        <template v-slot:[`item.id`]="{ item }">
          <cell-clipboard :text="item.id" />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CellClipboard from "@/components/common/CellClipboard.vue";

export default {
  components: {
    CellClipboard,
  },
  props: ["feeSchedule"],
  data() {
    return {
      show: false,
      loading: false,
      entityHeaders: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
      ],
    };
  },
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    entities() {
      if(this.feeSchedule) {
        return [{
          id: this.feeSchedule.id,
          name: this.feeSchedule.name
        }]
      } else {
          return [{
            id:"",
            name:""
          }]
      }
    }
  },
  methods: {
    showEntities() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped>
</style>
